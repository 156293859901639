var render = function render(_c, _vm) {
  return _c("strong", { staticClass: "gross-total" }, [
    _vm._v(
      " " +
        _vm._s(
          _vm.parent.$n(
            _vm.props.item.deliveredGrossTotal || _vm.props.item.grossTotal,
            "currency"
          )
        ) +
        " "
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }