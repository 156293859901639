var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "generic-list scroll-container" },
    [
      _c("CategoryTitle", { attrs: { category: _vm.category } }),
      _vm.orders && _vm.orders.length > 0
        ? _c("OrdersTable", {
            attrs: { orders: _vm.orders },
            on: {
              delete: _vm.deleteOrder,
              addAllToCart: _vm.addAllToCart,
              editOrder: _vm.editOrder
            }
          })
        : _vm._e(),
      _vm.orderPager && _vm.orderPager.totPages > 1
        ? _c("v-pagination", {
            attrs: {
              value: _vm.pageFilter,
              page: _vm.orderPager.selPage,
              length: _vm.orderPager.totPages ? _vm.orderPager.totPages : 0,
              totalVisible: _vm.$vuetify.breakpoint.xs ? 4 : 6,
              color: "secondary"
            },
            on: {
              next: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.handlePageFilter.apply(null, arguments)
              },
              prev: _vm.handlePageFilter,
              input: _vm.handlePageFilter
            }
          })
        : _vm._e(),
      _vm.orders && _vm.orders.length == 0
        ? _c(
            "v-card",
            {
              staticClass: "pa-2 text-center",
              staticStyle: { width: "100%" },
              attrs: { light: "", outlined: "" }
            },
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Nessun ordine trovato")
              ])
            ],
            1
          )
        : _vm._e(),
      _c("v-spacer", { staticClass: "spacer-scroll" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }