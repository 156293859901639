var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orders-table" },
    [
      _c(
        "v-row",
        {
          staticClass: "text-body-2 secondary--text font-weight-bold mt-5 mb-2",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            { staticClass: "hidden-md-and-down", attrs: { cols: "1" } },
            [_c("strong", [_vm._v("N°")])]
          ),
          _c(
            "v-col",
            { staticClass: "hidden-sm-and-down", attrs: { cols: "2" } },
            [_c("strong", [_vm._v("Data ordine")])]
          ),
          _c("v-col", { attrs: { cols: "3", md: "2" } }, [
            _c("strong", [_vm._v("Stato Ordine")])
          ]),
          _c("v-col", { attrs: { cols: "7", md: "5", lg: "5" } }, [
            _c("strong", [_vm._v("Consegna/Ritiro")])
          ]),
          _c("v-col", { attrs: { cols: "2", md: "2", lg: "1" } }, [
            _c("strong", [_vm._v("Totale")])
          ]),
          _c("v-col", {
            staticClass: "hidden-sm-and-down",
            attrs: { cols: "1" }
          })
        ],
        1
      ),
      _c(
        "v-list",
        _vm._l(_vm.orders, function(order) {
          return _c(
            "v-list-item",
            {
              key: order.orderId,
              attrs: { to: "/profile/orders/" + order.orderId }
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "hidden-md-and-down", attrs: { cols: "1" } },
                    [
                      _c("strong", { staticClass: "order-id" }, [
                        _vm._v("#" + _vm._s(order.orderId))
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "secondary--text hidden-sm-and-down",
                      attrs: { cols: "2" }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm
                                .$dayjs(order.addDate)
                                .format("DD/MM/YYYY - HH:mm")
                            ) +
                            " "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3", md: "2" } },
                    [
                      _c("OrderStatusBadge", {
                        attrs: { order: order, disabled: true }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "7", md: "5", lg: "5" } }, [
                    _c(
                      "span",
                      { staticClass: "text-capitalize d-flex flex-column" },
                      [
                        _c("span", { staticClass: "d-block d-lg-none" }, [
                          _vm._v(" Ordine n." + _vm._s(order.orderId) + " ")
                        ]),
                        _c("span", { staticClass: "secondary--text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "navbar.address." +
                                    order.shippingAddress.addressTypeId +
                                    ".format",
                                  order.shippingAddress
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("span", { staticClass: "secondary--text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .$dayjs(
                                    order.timeslot.date +
                                      " " +
                                      order.timeslot.beginTime,
                                    "YYYY-MM-DD hh:mm:ss A"
                                  )
                                  .format("D MMMM | HH:mm")
                              ) +
                              " "
                          )
                        ])
                      ]
                    )
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "2", md: "2", lg: "1" } },
                    [_c("OrderGrossTotal", { attrs: { item: order } })],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "actions-order justify-end hidden-sm-and-down",
                      attrs: { cols: "1" }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    order.isEditable
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "secondary--text ml-3",
                                                attrs: {
                                                  icon: "",
                                                  width: "40",
                                                  "min-width": "40"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.editOrder(
                                                      order.orderId
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_c("v-icon", [_vm._v("$edit")])],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Modifica ordine")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    order.isDeletable
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "secondary--text ml-3",
                                                attrs: {
                                                  icon: "",
                                                  width: "40",
                                                  "min-width": "40"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.deleteOrder(
                                                      order.orderId
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_c("v-icon", [_vm._v("$delete")])],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Annulla ordine")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    order.orderStatusId == 8 ||
                                    order.orderStatusId == 10
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "main-button",
                                                attrs: {
                                                  large: "",
                                                  icon: "",
                                                  color: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.addAllToCart(
                                                      order.orderId
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_c("v-icon", [_vm._v("$cart")])],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v("Aggiungi tutto al carrello")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }